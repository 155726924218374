export const getHeaderHeight = (isDesktop: boolean) => (isDesktop ? 64 : 56);
export const getCategoriesHeight = (): 48 => 48;
export const getJackpotsHeight = () => 48;

export const getCarouselMarginTop = ({
  isDesktop,
  withCategories,
  withJackpots
}: {
  isDesktop: boolean;
  withCategories?: boolean;
  withJackpots?: boolean;
}) => {
  const mt =
    getHeaderHeight(isDesktop) +
    (withCategories ? getCategoriesHeight() : 0) +
    (withJackpots ? getJackpotsHeight() : 0);

  return { value: -mt, css: `${-1 * mt}px` };
};

export const hasJackpotsInHeader = ({
  pathname,
  enabled
}: {
  pathname: string;
  enabled: boolean;
}) => {
  return (
    enabled &&
    ['/[segment]/category/[slug]', '/[segment]/section/[slug]'].some(
      (path) => path === pathname
    )
  );
};

export const hasCategoriesInHeader = ({ pathname }: { pathname: string }) => {
  return [
    '/[segment]',
    '/[segment]/category/[slug]',
    '/[segment]/tournaments'
  ].some((path) => path === pathname);
};
