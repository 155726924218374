import { useEffect, useRef } from 'react';

export const useDisableScrollWheel = <T extends HTMLElement>() => {
  const inputRef = useRef<T>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.currentTarget === inputRef.current) {
        event.preventDefault();
      }
    };

    const input = inputRef.current;
    if (input) {
      input.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (input) {
        input.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return inputRef;
};
