import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { HTTP } from '@/components';
import { JackpotHitType, JackpotType } from '@/components/Jackpots';
import { useAppSettings } from '@/context/AppSettings';

type JackpotsContextType = {
  enabled: boolean;
  jackpots?: JackpotType[];
  setJackpots: (jackpots: JackpotType[]) => void;
  modalJackpotHits: JackpotHitType[];
  setModalJackpotHits: (data: JackpotHitType[]) => void;
  setJackpotUpdateTime: (time: number) => void;
  addJackpotHit: (jackpotHit: JackpotHitType) => void;
  removeJackpotHit: (jackpotHit: JackpotHitType) => void;
};

export const JackpotsContext = createContext<JackpotsContextType>({
  enabled: false,
  jackpots: [],
  setJackpots: (jackpots: JackpotType[]) => {},
  modalJackpotHits: [],
  setModalJackpotHits: (data: JackpotHitType[]) => {},
  setJackpotUpdateTime: (t: number) => {},
  addJackpotHit: (jackpotHit: JackpotHitType) => {},
  removeJackpotHit: (jackpotHit: JackpotHitType) => {}
});

export const useJackpots = () => useContext(JackpotsContext);

export const JackpotsProvider = ({ children }: PropsWithChildren) => {
  const {
    features: {
      jackpot: { enabled }
    }
  } = useAppSettings();
  const [jackpots, setJackpots] = useState<JackpotType[]>();
  const [modalJackpotHits, setModalJackpotHits] = useState<JackpotHitType[]>(
    []
  );
  const [jackpotUpdateTime, setJackpotUpdateTime] = useState<number>(0);

  const addJackpotHit = (jackpotHit: JackpotHitType) => {
    setModalJackpotHits((prevState) => [jackpotHit, ...prevState]);
  };

  const removeJackpotHit = (jackpotHit: JackpotHitType) => {
    setModalJackpotHits((prevState) => [
      ...prevState.filter(
        (modal: JackpotHitType) => modal.code !== jackpotHit.code
      )
    ]);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const loadJackpots = async () => {
      if (!enabled) return;
      const response = await HTTP.get(`jackpots`);
      // console.log('Jackpots -> loadJackpots', response?.data?.rows);
      setJackpots(response?.data?.rows);

      // in order to have updated data, we trigger a load at the end of upcoming.timestamp
      const firstJackpot = Array.from(response?.data?.rows || []).shift() as
        | JackpotType
        | undefined;
      if (firstJackpot) {
        const firstUpcoming = firstJackpot.upcoming.shift();
        if (firstUpcoming?.timestamp) {
          const remaining = Math.max(
            0,
            new Date(firstUpcoming.timestamp).getTime() - Date.now()
          );
          if (remaining) {
            // extended waiting time
            timer = setTimeout(() => void loadJackpots(), remaining + 3 * 1000);
          }
        }
      }
    };
    void loadJackpots();

    return () => timer && clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jackpotUpdateTime]);

  return (
    <JackpotsContext.Provider
      value={{
        enabled,
        jackpots,
        setJackpots,
        modalJackpotHits,
        setModalJackpotHits,
        addJackpotHit,
        removeJackpotHit,
        setJackpotUpdateTime
      }}
    >
      {children}
    </JackpotsContext.Provider>
  );
};
