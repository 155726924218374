import Favicon from 'react-favicon';
import {
  Box,
  Button,
  Portal,
  StackProps,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { CloseNotificationIcon } from '@/theme/Icons';
import { NotificationContext, UserNotification } from '@/context/Notification';
import { timeAgo } from '@/helpers/humanDate';
import getConfig from 'next/config';
import { ElementRef, FC, useContext, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { HTTP } from '@/components/Http';
import useMediaQuery from '@/hooks/useMediaQuery';
import router, { useRouter } from 'next/router';
import useResizeObserver from '@/hooks/useResizeObserver';
import GTM from '@/helpers/googleTagManager';
import { useAppSettings } from '@/context/AppSettings';
import ExitDialog from '../ExitDialog';
import { getFallbackLanguage } from '@/helpers/lang';
import {
  getCategoriesHeight,
  getHeaderHeight,
  getJackpotsHeight,
  hasCategoriesInHeader,
  hasJackpotsInHeader
} from '@/helpers/header';
import { useBodyClick } from '@/hooks/useBodyClick';

type NotificationOverview = StackProps & {};

const NotificationOverview: FC<NotificationOverview> = ({}) => {
  const notificationContext = useContext(NotificationContext);
  const { lang, t } = useTranslation();
  const { publicRuntimeConfig } = getConfig();
  const appName = publicRuntimeConfig.currentAppConfig.appName;
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const dimensions = useResizeObserver();
  const appSettings = useAppSettings();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const ref = useRef<ElementRef<'div'>>(null);
  const [currentNotification, setCurrentNotification] =
    useState<UserNotification>();

  // disable overflow scroll body on screen mobile
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (notificationContext.notificationOverviewOpened && isScreenMobile) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = '';
      }
    }
  }, [
    isScreenMobile,
    notificationContext.notificationOverviewOpened,
    dimensions
  ]);

  const markNotificationSeen = async (notificationId: string) => {
    try {
      await HTTP.patch(
        `notification/notifications/${notificationId}/mark_as_seen`,
        {}
      );
    } catch (error) {
      console.error(error);
    }
  };

  const markAllUserNotificationsAsRead = () => {
    if (notificationContext.unreadUserNotifications.length > 0) {
      markUserNotificationAsRead(notificationContext.unreadUserNotifications);
    }
  };

  const markUserNotificationAsRead = (ids: string[]) => {
    Promise.all(ids.map((id) => markNotificationSeen(id))).then(() => {
      notificationContext.loadUserNotifications();
    });
  };

  const cleanUrl = (url: string): string => {
    if (!url.match(/^https?:\/\//i)) {
      url = 'https://' + url;
    }
    return url;
  };

  const onClickNotification = (notification: UserNotification) => {
    GTM.notificationEvent('notification_click', notification.content);
    if (!notification.seenAt) markUserNotificationAsRead([notification.id]);
    if (notification.redirectTarget === '_blank') {
      window.open(cleanUrl(notification.redirectUrl), '_blank');
    } else {
      const currentRoute = router.pathname;
      router
        .push(
          cleanUrl(notification.redirectUrl),
          cleanUrl(notification.redirectUrl),
          { locale: getFallbackLanguage(lang) }
        )
        .then(() => {
          if (currentRoute === router.pathname) {
            router.reload();
          }
        });
    }
    // close notification after click
    notificationContext.setNotificationOverviewOpened(false);
  };

  // used in position and height calculation of the notification overlay
  const { pathname } = useRouter();
  const hasJackpots = hasJackpotsInHeader({ pathname, enabled: true });
  const hasCategories = hasCategoriesInHeader({ pathname });

  const headerHeight = getHeaderHeight(!isScreenMobile);
  const categoriesHeight = hasCategories ? getCategoriesHeight() : 0;
  const jackpotsHeight = hasJackpots ? getJackpotsHeight() : 0;
  const maxHeight = `100vh - ${headerHeight}px - ${categoriesHeight}px - ${jackpotsHeight}px`;
  const gap = 8;

  // add event listener to close NotificationOverview
  // when clicking outside of the NotificationOverview and the notification button
  useBodyClick({
    condition: notificationContext.notificationOverviewOpened,
    onBodyClick: () => notificationContext.setNotificationOverviewOpened(false),
    btnSelector: '#btn-open-notification-overview',
    refOverlay: ref
  });

  return (
    <Portal>
      <Favicon
        url={`/${appName}/favicon-32x32.png`}
        iconSize={32}
        alertCount={notificationContext.unreadUserNotifications.length}
      />
      {notificationContext.notificationOverviewOpened && (
        <Box
          ref={ref}
          id="notification-overview"
          data-testid="notification-overview"
          position={'fixed'}
          overflowY="auto"
          width={{ base: '100%', xs: '370px', md: '370px' }}
          transition={'height ease .3s, top ease .3s, bottom ease .3s'}
          height={{ base: '100%', xs: 'auto' }}
          maxHeight={{
            base: '100vh',
            xs: `calc(${maxHeight} - var(--height-mobile-menu-bottom, 0px) - var(--top-header, 0px) - ${gap}px)`,
            sm: `calc(${maxHeight} - var(--height-mobile-menu-bottom, 0px) - var(--top-header, 0px) - ${gap}px)`,
            md: `calc(${maxHeight} - var(--top-header, 0px) - ${gap}px - ${gap}px)`
          }}
          left={{ base: '0', xs: 'auto', md: 'auto' }}
          right={{
            base: '0',
            xs: '50%',
            md: 'calc((100vw - min(100%, 1280px) + 2rem ) / 2)'
          }}
          top={{
            base: '0',
            xs: 'auto',
            md: `calc(${headerHeight}px + ${categoriesHeight}px + ${jackpotsHeight}px + var(--top-header, 0px) + ${gap}px)`
          }}
          bottom={{
            base: 'initial',
            xs: 'calc(var(--height-mobile-menu-bottom, 0px) + 8px)',
            md: 'initial'
          }}
          // centered for xs and sm
          transform={['none', 'translateX(50%)', 'translateX(50%)', 'none']}
          backgroundColor="backgroundPrimary.900"
          backdropBlur="0.469rem"
          borderWidth={{ base: '0', xs: '1px' }}
          borderColor="lightWhite"
          borderStyle="solid"
          borderRadius={{ base: '0', xs: '1.25rem', md: '1.25rem' }}
          padding="1.25rem 1rem"
          zIndex="99999999"
          display={'flex'}
          flexDirection={'column'}
        >
          <Box
            flexGrow={0}
            flexShrink={0}
            className="notification-header"
            display="flex"
            alignItems="center"
            position="relative"
            marginBottom="1rem"
          >
            <Text fontWeight="bold" fontSize="1.1rem">
              {t('notifications:notifications')}
            </Text>
            <CloseNotificationIcon
              marginLeft="auto"
              cursor="pointer"
              onClick={() =>
                notificationContext.setNotificationOverviewOpened(false)
              }
            />
          </Box>
          {notificationContext.userNotifications &&
          notificationContext.userNotifications.length > 0 ? (
            <>
              <Box
                data-testid="notification-list"
                className="notification-list"
                flexGrow={1}
                flexShrink={1}
                overflow="auto"
              >
                {notificationContext.userNotifications.map(
                  (notification: UserNotification, index: number) => (
                    <Box
                      key={`${notification.id}-${index}`}
                      background={
                        !notification.seenAt ? 'brand.200' : 'customDarkGrey'
                      }
                      borderColor="buttonPrimary"
                      borderStyle="solid"
                      borderWidth={notification.seenAt ? '0' : '1px'}
                      borderRadius="1.25rem"
                      cursor="pointer"
                      display="flex"
                      maxWidth="100%"
                      marginTop="0.625rem"
                      className="notification"
                      minWidth="20rem"
                      width="100%"
                      padding="0.75rem"
                      onClick={() => {
                        if (appSettings.isPlayingGame) {
                          onOpen();
                          setCurrentNotification(notification);
                          appSettings.setTriggerCloseGame(false);
                        } else {
                          onClickNotification(notification);
                        }
                      }}
                    >
                      <Box
                        flexGrow="0"
                        flexShrink="0"
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        w={12}
                      >
                        <Box
                          as="span"
                          display="inline-block"
                          height="0.625rem"
                          width="0.625rem"
                          background={
                            !notification.seenAt
                              ? 'customRed'
                              : 'customGrey.200'
                          }
                          borderRadius="50%"
                        ></Box>
                      </Box>
                      <Text
                        fontSize="0.938rem"
                        fontFamily="Galano"
                        fontWeight="700"
                        flexGrow="1"
                        flexShrink="1"
                      >
                        <span data-testid="notification-item-content">
                          {notification.content}
                        </span>
                        <Text
                          as="span"
                          fontSize="0.688rem"
                          fontWeight="500"
                          display="block"
                        >
                          {timeAgo(new Date(notification.sentAt), lang)}
                        </Text>
                      </Text>
                    </Box>
                  )
                )}
              </Box>
              <Box
                flexGrow={0}
                flexShrink={0}
                className="notification-footer"
                width="100%"
                marginTop="1.75rem"
                marginBottom="1rem"
                textAlign="center"
              >
                <Button
                  display="inline-block"
                  color="buttonSecondary"
                  textDecoration="underline"
                  bg="none"
                  boxShadow="none"
                  cursor="pointer"
                  fontSize="0.938rem"
                  fontWeight="500"
                  _hover={{
                    background: 'none',
                    color: 'buttonPrimary'
                  }}
                  border="none"
                  outline="0"
                  minWidth="inherit"
                  padding="0"
                  borderRadius="0"
                  height="auto"
                  lineHeight="normal"
                  verticalAlign="middle"
                  onClick={() => {
                    markAllUserNotificationsAsRead();
                  }}
                >
                  {t('notifications:markAllAsRead')}
                </Button>
              </Box>
            </>
          ) : (
            <Box
              minWidth="20rem"
              height="10rem"
              textAlign="center"
              lineHeight="10rem"
            >
              {t('notifications:noNotifications')}
            </Box>
          )}
        </Box>
      )}
      <ExitDialog
        isDialogOpen={isOpen}
        alertOnAccept={() => {
          onClickNotification(currentNotification as UserNotification);
          setCurrentNotification(undefined);
          onClose();
          appSettings.setTriggerCloseGame(true);
          notificationContext.setNotificationOverviewOpened(false);
        }}
        alertOnDenied={() => {
          onClose();
          appSettings.setTriggerCloseGame(false);
        }}
      />
    </Portal>
  );
};

export default NotificationOverview;
