import { useStepper } from '@/components/Stepper';
import { useSettings } from '@/components/UserSettings/SettingsContext';
import { useAuth } from '@/context/Auth';
import {
  Button,
  Checkbox,
  Divider,
  HStack,
  InputGroup,
  Select,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useState } from 'react';
import { humanReadableBytes } from '../../../../helpers/humanReadableBytes';
import { ChevronIcon, IdIcon } from '../../../../theme/Icons';
import DropZone from '../../../DropZone/DropZone';
import { HTTP } from '../../../Http';
import { APIDocumentType } from './MyDocuments.types';
import ProgressLine from './ProgressLine';
import { InputLabel } from '@/components';

type DocumentType = {
  label: string;
  maximumMedia: number;
  minimumMedia: number;
  path: string;
  type: string;
};

const Upload = () => {
  const [currentFiles, setCurrentFiles] = useState<{
    [index: number]: File;
  } | null>(null);
  const [errors, setErrors] = useState<any>(null);
  const [checkedConfirmation, setCheckedConfirmation] =
    useState<boolean>(false);
  const [inUpload, setInUpload] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [currentDoc, setCurrentDoc] = useState<APIDocumentType | null>();
  const { setCurrentDocuments, setCurrentStatus } = useSettings();
  const { back } = useStepper();
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState<DocumentType | null>(null);
  const { userData } = useAuth();

  const maxFileSize = 20971520;
  const acceptedFiles = ['image/jpg', 'image/jpeg', 'image/png'];

  const files = [
    {
      key: 'front',
      label: t('account:frontIDCard')
    },
    {
      key: 'back',
      label: t('account:backIDCard')
    }
  ];

  const toast = useToast();

  useEffect(() => {
    const getDocumentTypes = async () => {
      try {
        const resp = await HTTP.get('user/documentTypes');
        const documentTypes = resp?.data;
        if (userData?.nationality !== 'BE')
          setDocumentTypes(
            documentTypes?.filter(
              (documentType: DocumentType) =>
                documentType.type !== 'identification'
            )
          );
        else setDocumentTypes(documentTypes);
      } catch (err) {
        toast({
          title: 'Failed to get document types',
          description: (err as Error).message,
          status: 'error',
          duration: 4000,
          position: 'top-right',
          isClosable: true
        });
      }
    };
    void getDocumentTypes();
  }, [toast, userData]);

  const displayAcceptedFilesTypes = acceptedFiles
    .map((el: string) => el.split('/')[1].toUpperCase())
    .join(', ');

  const onProgress = (curId: number, curValue: number) => {};

  const cleanImage = (
    index: number,
    newError?: string,
    deleteErrors: boolean = true
  ) => {
    if (!!deleteErrors) {
      const newErrors = () => {
        let temp = { ...errors };
        delete temp[index];
        return temp;
      };
      setErrors({ ...newErrors(), [index]: newError });
    } else {
      setErrors((errors: any) => ({ ...errors, [index]: newError }));
    }
    setUploaded(false);
    setInUpload(false);
    removeImage(index);
    setCurrentDoc(null);
    setCheckedConfirmation(false);
  };

  const handleSubmission = async () => {
    if (!userData?.id) return;
    setInUpload(true);

    let doc = currentDoc ? { ...currentDoc } : undefined;
    if ((!doc || !doc.id) && documentType) {
      // create doc
      try {
        const res = await HTTP.post(`/user/${documentType.path}`, {
          user: '/users/' + userData.id
        });
        doc = res.data;
        if (!doc || !doc.id) throw new Error('Wrong data from api');
        setCurrentDoc(doc);
      } catch {
        toast({
          title: t('account:uploadError'),
          description: <>{t('account:anErrorOccuredWhileSelectingFiles')}</>,
          status: 'error',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
        setInUpload(false);
        return;
      }
    }

    if (currentFiles && documentType) {
      const [frontCard, backCard] = await Promise.allSettled(
        Object.values(currentFiles).map((curFile, curId) => {
          return new Promise(async (resolve, reject) => {
            const url = `/user/${documentType.path}/${doc?.id}/upload/${files[curId].key}`;
            try {
              const form = new FormData();

              // send file
              form.append('file', curFile);

              const response = await HTTP.post(url, form, {
                onUploadProgress: ({
                  total,
                  loaded
                }: {
                  total: number;
                  loaded: number;
                }) => {
                  onProgress(curId, Math.round((loaded / total) * 100));
                }
              });

              resolve(response);
            } catch (err: any) {
              reject(err);
              // reject(err);
            }
          });
        })
      );

      if (frontCard.status === 'fulfilled') {
        try {
          const submitUploadResponse = await HTTP.post(
            `/user/documents/${doc?.id}/submit`
          );

          if (submitUploadResponse?.data?.status === 'uploaded') {
            toast({
              title: t('account:documentUploaded'),
              description: t('account:uploadSuccessfull'),
              status: 'success',
              position: 'top',
              duration: 4000,
              isClosable: true
            });
            setUploaded(true);
            // reset form
            setCurrentFiles(null);
            setCheckedConfirmation(false);
            setCurrentDoc(submitUploadResponse.data);
            setInUpload(false);
            setCurrentDocuments([submitUploadResponse?.data]);
            setCurrentStatus(submitUploadResponse?.data?.status);
            return;
          }
        } catch (err) {
          console.error('error to submit document', err);
          // nothing to do, it will go to 1st step when lastData is empty
        }
      }
    }
  };

  const removeImage = (index: number) => {
    let newCurrentFiles = { ...currentFiles };
    delete newCurrentFiles[index];
    setCurrentFiles(newCurrentFiles);
  };

  return (
    <VStack width="100%" align="baseline">
      <HStack>
        <IdIcon w={10} h={10} />
        <Text as="h1" textStyle="h1">
          {t('form:uploadDocuments')}
        </Text>
      </HStack>

      <InputGroup marginBottom="3rem !important">
        <Select
          id={'document-type'}
          data-testid="change-document-type"
          flex="1"
          fontWeight="bold"
          height="4.8rem"
          fontSize="1.2rem"
          bg="backgroundPrimaryDarker"
          onChange={(e) => {
            const value = e.currentTarget.value;
            const documentType = documentTypes.find(
              (documentType) => documentType.type === value
            );
            setDocumentType(documentType ?? null);
          }}
        >
          <option></option>
          {documentTypes?.map((documentType) => (
            <option
              key={`documentType-${documentType.type}`}
              value={documentType.type}
            >
              {documentType.label}
            </option>
          ))}
        </Select>
        <InputLabel
          htmlFor={'document-type'}
          label={'Document type'}
          color="whiteAlpha.600"
          position="absolute"
          zIndex={10}
          left="1rem"
          maxWidth={'calc(100% - 3rem)'}
          transition="all .1s ease-in-out"
          fontSize={['0.8rem', '1rem', '1.2rem']}
          top="50%" // fix this value because error text can expand height of container and so label goes down
          transform="translateY(-50%)"
          // width="99%"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          className="float-label"
          style={documentType ? { top: '0.8rem', fontSize: '0.7rem' } : {}}
        />
      </InputGroup>

      {documentType ? (
        <>
          <Stack
            width="100%"
            spacing="2rem"
            direction={['column', 'row']}
            justifyContent="space-between"
          >
            {Array.from(Array(documentType.maximumMedia), (e, index) => (
              <DropZone
                key={index}
                title={
                  t(`form:uploadDocument${index + 1}`) ??
                  t('form:genericUploadDocument') + ' ' + (index + 1)
                }
                acceptedFiles={acceptedFiles}
                maxFileSize={maxFileSize}
                error={errors?.[index]}
                onSetFile={(file?: File | null) => {
                  file && setCurrentFiles({ ...currentFiles, [index]: file });
                }}
                onClean={() => {
                  cleanImage(index);
                }}
              />
            ))}
          </Stack>

          <Text
            width="100%"
            textAlign="center"
            color="customGrey.900"
            role="contentinfo"
            marginBottom="3rem !important"
          >
            {displayAcceptedFilesTypes}. {t('account:maximumSize')}{' '}
            {humanReadableBytes(String(maxFileSize))}
          </Text>

          <Stack
            width="100%"
            direction={['column', 'row']}
            justifyContent="space-between"
            spacing="2rem"
          >
            <Checkbox
              data-testid="confirm-documents-checkbox"
              size="md"
              colorScheme="red"
              onChange={() => setCheckedConfirmation(!checkedConfirmation)}
              isChecked={checkedConfirmation}
              isDisabled={!currentFiles}
            >
              {t('account:confirmDocuments')}
            </Checkbox>
            {inUpload ? (
              <ProgressLine
                label={t('account:uploadPleaseWait')}
                requestDuration="3s" // todo: create state with % from HTTP
                backgroundColor="#8A8E99"
                visualParts={[
                  {
                    percentage: '6%',
                    color: '#54001b'
                  },
                  {
                    percentage: '22%',
                    color: '#7e0333'
                  },
                  {
                    percentage: '22%',
                    color: '#9b023d'
                  },
                  {
                    percentage: '50%',
                    color: '#F00050'
                  }
                ]}
              />
            ) : (
              <Button
                size="lg"
                onClick={handleSubmission}
                data-testid="send-my-documents-button"
                backgroundColor="buttonPrimary"
                disabled={!currentFiles || !checkedConfirmation}
              >
                {t('account:uploadDocuments')}
              </Button>
            )}
          </Stack>
        </>
      ) : null}
      <Divider marginY="2rem !important" />
      <Button
        variant="link"
        onClick={back}
        leftIcon={<ChevronIcon w={8} h={10} transform="rotate(90deg)" />}
      >
        {t('account:previousStep')}
      </Button>
    </VStack>
  );
};

export default Upload;
