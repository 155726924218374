import { Provider } from '@/components/GamesCatalog/types';
import { Text, Button, ButtonProps, Image } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';
import React, { FC } from 'react';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import useTranslation from 'next-translate/useTranslation';
import { useExtraProps } from '@/pages/_app';

export type ProviderButtonProps = ButtonProps & {
  provider: Pick<Provider, 'name' | 'mediaUrls' | 'id' | 'slug'>;
};

export const ProviderButton: FC<ProviderButtonProps> = ({
  provider,
  ...props
}: ProviderButtonProps) => {
  const { lang } = useTranslation();
  const { license } = useExtraProps();
  const logoSrc = provider.mediaUrls?.logo.url;
  const name = provider.name;
  return (
    <Button
      as={'a'}
      href={`${getFullUrlByLicense(license, lang)}/providers/${provider.id}/${
        provider.slug
      }`}
      data-testid={`provider-button-${name}`}
      border="1px solid rgba(255,255,255, 0.2)"
      borderRadius={toRem(10)}
      boxShadow="0px 2px 4px 0px rgba(255,255,255, 0.1)"
      background="backgroundPrimaryDarker"
      overflow="hidden"
      minWidth={[toRem(150), toRem(160)]}
      // width={[toRem(150), toRem(160)]}
      height={[toRem(54), toRem(60)]}
      display={'flex'}
      flexShrink={0}
      justifyContent={'center'}
      alignItems={'center'}
      padding={`${toRem(8)} ${toRem(24)}`}
      margin={0}
      {...props}
    >
      {logoSrc ? (
        <Image src={logoSrc} alt={name ?? 'unknown'} />
      ) : (
        <Text as={'span'}>{name}</Text>
      )}
    </Button>
  );
};
