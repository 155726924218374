import BorderedBox from '@/components/Box';
import { useAuth } from '@/context/Auth';
import GTM from '@/helpers/googleTagManager';
import { CurrencyBase, useFormatCurrency } from '@/hooks/useFormat';
import useMediaQuery from '@/hooks/useMediaQuery';
import { ChevronIcon } from '@/theme/Icons';
import {
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { useWallet } from '../../../../context/Wallet';
import SliderSnap from '../../../SliderSnap/SliderSnap';
import Stepper from '../../../Stepper/Stepper';
import { useSettings } from '../../SettingsContext';
import WalletQuickView from '../QuickView/WalletQuickView';
import { WalletCashInFormProps } from './CashInForm.types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAppSettings } from '@/context/AppSettings';
import { useDisableScrollWheel } from '@/hooks/useDisableScrollWheel';

export type PaymentMethod = {
  id: string;
  provider: string;
  method: string;
  badge?: string;
  icon: string;
  customComponent?: JSX.Element;
};

const WalletCashInForm: FC<WalletCashInFormProps> = ({ onSelect }) => {
  const { paymentMethods } = useSettings();
  const config = useAppSettings();

  const paymentAmounts: PaymentAmount[] = [
    {
      value: 25,
      label: useFormatCurrency({
        amount: 25,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 50,
      label: useFormatCurrency({
        amount: 50,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 100,
      label: useFormatCurrency({
        amount: 100,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 200,
      label: useFormatCurrency({
        amount: 200,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 300,
      label: useFormatCurrency({
        amount: 300,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 400,
      label: useFormatCurrency({
        amount: 400,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 500,
      label: useFormatCurrency({
        amount: 500,
        maximumFractionDigits: 0,
        currencyBase: CurrencyBase.CENT
      })
    }
  ];

  const isAbove = (amountInEuros: number, limitInCents = 500) => {
    const amountInCents = amountInEuros * 100;
    return amountInCents > limitInCents;
  };

  const { userData } = useAuth();
  const { balance, asideChildren } = useWallet();
  // trigger next Step in stepper manually from component called inside stepper
  const [currentStep, setHandleNextStep] = useState<number>(0);

  const findHighestValue = () => {
    let temp = [
      ...paymentAmounts.filter((p) => !isAbove(p.value, balance?.available))
    ];
    return Math.max(...temp.map((paymentAmount) => paymentAmount.value));
  };

  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<string>('');
  const [selectedAmount, setSelectedAmount] = useState<number>(
    findHighestValue()
  );
  const { t } = useTranslation();

  const selectedPaymentMethod = paymentMethods.find(
    (x) => x.method === selectedMethod
  );

  let minimumDeposit = config.minimumDeposit;
  minimumDeposit =
    minimumDeposit?.[selectedPaymentMethod?.id as string] ||
    minimumDeposit.default;

  // const amountRequired = useFormatCurrency({ amount: minimumCashOutRequired });
  const { isOpen, onClose, onOpen } = useDisclosure();

  type PaymentAmount = {
    value: number;
    label: string;
  };

  const Aside = () =>
    asideChildren ? <aside className="margin2">{asideChildren}</aside> : null;

  useEffect(() => {
    findHighestValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  useEffect(() => {
    // setSelectedAmount();
    onSelect && onSelect(selectedProvider, selectedMethod, selectedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider, selectedMethod, selectedAmount]);

  const isSmMediaQuery = useMediaQuery('(max-width: sm)');
  const selectedPaymentCard = paymentMethods.filter((el) =>
    el.method.includes(selectedMethod)
  );

  // step 1
  const SelectPaymentMethod = (
    <>
      <VStack
        spacing="2rem"
        align={['center', 'center', 'baseline']}
        marginX={['-1rem', '-1rem', 0]}
      >
        <Text as="h3" fontSize="1.5rem" fontWeight="bold" padding={'0 1rem'}>
          {t('wallet:chooseYourPaymentMethod')}
        </Text>
        {isSmMediaQuery ? (
          <SliderSnap
            data={paymentMethods}
            onSlideClick={(element) => {
              setSelectedProvider(element?.provider);
              setSelectedMethod(element.method);
              setHandleNextStep(currentStep + 1);
            }}
          />
        ) : (
          <HStack flexFlow={'wrap'} gap={'1em'} padding={'1em'}>
            {paymentMethods?.map((paymentMethod, i) => {
              return (
                <IconButton
                  data-testid={paymentMethod.id}
                  key={paymentMethod.id}
                  backgroundColor="white"
                  aria-label="payment-button"
                  onClick={() => {
                    GTM.ecommerceEvent(
                      'add_payment_info',
                      undefined,
                      paymentMethod,
                      userData,
                      balance,
                      !userData?.alreadyDeposited
                    );
                    setSelectedProvider(paymentMethod.provider);
                    setSelectedMethod(paymentMethod.method);
                    setHandleNextStep(currentStep + 1);
                  }}
                  borderRadius="1rem"
                  // border="5px solid white"
                  margin={0}
                  marginInlineStart={'0 !important'}
                  width="145px"
                  height="95px"
                  padding=".2rem"
                  border="none"
                  _hover={{
                    //border: '2px solid buttonPrimary',
                    transform: 'scale(1.1)'
                  }}
                  position="relative"
                  icon={
                    <Img
                      // width="100%"
                      // height="100%"
                      maxHeight="90%"
                      maxWidth="90%"
                      position="absolute"
                      src={paymentMethod.icon}
                      alt={`${paymentMethod.provider} : ${paymentMethod.method}`}
                      title={`${
                        paymentMethod.method
                      } (${paymentMethod.provider.toLowerCase()})`}
                    />
                  }
                />
              );
            })}
          </HStack>
        )}
      </VStack>
      <WalletQuickView marginTop="2rem" />
    </>
  );

  const ModalDepositError = () => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader> */}
        <ModalCloseButton />
        <ModalBody p="2rem">{t('wallet:depositLimitError')}</ModalBody>
        <ModalFooter>
          <Button
            mr={3}
            onClick={() => {
              onClose();
              router.push('/me/settings/depositLimit');
            }}
            backgroundColor={'buttonPrimary'}
          >
            {t('wallet:setNewLimit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const toast = useToast();
  const from = useFormatCurrency({ amount: minimumDeposit, currencyBase: 1 });
  const to = useFormatCurrency({
    amount: (balance?.available || minimumDeposit * 100) / 100,
    currencyBase: 1
  });
  const router = useRouter();

  const checkAmount = () => {
    if (isAbove(selectedAmount, balance?.available)) {
      // toast({
      //   title: t('wallet:maximumCashOutExceeded'),
      //   status: 'warning',
      //   isClosable: true,
      //   duration: 4000,
      //   position: 'top'
      // });
      // t('wallet:depositLimitError')
      // setErrorModalStatus(true);
      onOpen();

      return setSelectedAmount(
        (balance?.available || minimumDeposit * 100) / 100
      );
    } else if (selectedAmount < minimumDeposit) {
      toast({
        title: t('wallet:minimumCashInRequired', {
          amount: `${minimumDeposit} €` // todo should be formatted with useFormatCurrency
        }),
        status: 'warning',
        isClosable: true,
        duration: 4000,
        position: 'top'
      });
      return setSelectedAmount(minimumDeposit);
    }
  };

  // step 2
  // shorter placeholder for  mobile
  const inputPlaceholder = isSmMediaQuery
    ? ''
    : `${t('wallet:orTypeAnAmount', {
        from,
        to
      })}`;

  const inputRef = useDisableScrollWheel();

  const SelectAmount = (
    <>
      <Text
        as="h3"
        width="100%"
        fontSize="1.5rem"
        fontWeight="bold"
        textAlign="left"
        marginBottom="2rem"
        display={['none', 'none', 'block']}
      >
        {t('wallet:chooseAmountOfDeposit')}
      </Text>
      <BorderedBox
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={['center', 'center', 'center', 'flex-start']}
        padding={['0.5rem', '0.5rem', '2rem']}
        borderBottom={['none', 'none', '1px solid rgba(142, 148, 179, 0.2)']}
        maxWidth="45rem"
        transform={['none', 'none', 'none', 'translate3D(2rem, 0, 0)']}
        spacing="1rem"
        overflow="visible"
      >
        <Img
          borderRadius="1rem"
          width={['100px', '100px', '140px']}
          height={['70px', '70px', '90px']}
          src={selectedPaymentCard[0].icon}
          alt={selectedPaymentCard[0].id}
          title={selectedPaymentCard[0].method}
          backgroundColor="#fff"
          padding={'.5rem'}
          transform={[
            'translate3D(0, -2rem, 0)',
            'translate3D(0, -2rem, 0)',
            'translate3D(0, -3rem, 0)',
            'translate3D(-4rem, 0, 0)'
          ]}
        />

        <Text
          as="h3"
          textStyle="h3"
          fontWeight="bold"
          width="100%"
          textAlign="center"
          marginBottom="1rem !important"
          display={['block', 'block', 'block', 'none']}
        >
          {t('wallet:chooseAmountOfDeposit')}
        </Text>

        <VStack>
          <Grid
            width="auto"
            gridTemplateColumns="repeat(3, 1fr)"
            gridGap="1rem"
            marginBottom="1.5rem !important"
          >
            {paymentAmounts.map((amount) => {
              const isChoiceDisabled =
                amount.value * 100 > (balance?.available || 0);
              return (
                <Box key={amount.label} position="relative">
                  <Button
                    width="100%"
                    height={['70px', '70px', '90px']}
                    borderRadius="12px"
                    key={amount.label}
                    fontSize={['1.2rem', '1.2rem', '2rem']}
                    fontWeight="bold"
                    color={
                      selectedAmount === amount.value ? 'white' : '#1F2544'
                    }
                    backgroundColor={
                      selectedAmount === amount.value
                        ? 'buttonPrimary'
                        : 'white'
                    }
                    _hover={{
                      backgroundColor: 'buttonPrimary',
                      color: 'white'
                    }}
                    disabled={isChoiceDisabled}
                    onClick={() => {
                      setSelectedAmount(amount.value);
                    }}
                    data-testid={'cashin-amount-' + amount.value}
                  >
                    {amount.label}
                  </Button>
                  {isChoiceDisabled ? (
                    <>
                      <Popover trigger="hover">
                        <PopoverTrigger>
                          <Box
                            as="span"
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height="100%"
                            zIndex={1}
                            tabIndex={0}
                            cursor="pointer"
                          ></Box>
                        </PopoverTrigger>
                        <PopoverContent bg="headerBackground.900">
                          <PopoverArrow bg="headerBackground.900" />
                          <PopoverCloseButton />
                          <PopoverBody p={'1rem'}>
                            <Link href={'/me/settings/depositLimit'}>
                              {t('wallet:deposit_increase_amount_info')}
                            </Link>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </>
                  ) : null}
                </Box>
              );
            })}
          </Grid>
          <InputGroup width="100%">
            <Input
              ref={inputRef as RefObject<HTMLInputElement>}
              size="lg"
              placeholder={inputPlaceholder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                // ensure to remove the leading 0
                e.target.value = (+value).toString();
                setSelectedAmount(+value);
              }}
              onBlur={checkAmount}
              value={selectedAmount}
              type="number"
              max={(balance?.available || minimumDeposit * 100) / 100}
              min={minimumDeposit}
              step={0.1}
              data-testid={'cashin-amount-input'}
            />
            <InputRightElement height="100%">
              <Text fontWeight="bold" fontSize="1.8rem">
                €
              </Text>
            </InputRightElement>
          </InputGroup>
          <ModalDepositError />
        </VStack>
        {isSmMediaQuery && (
          <Button
            paddingLeft="2rem"
            onClick={() => {
              setHandleNextStep(currentStep + 1);
            }}
            backgroundColor="buttonPrimary"
            disabled={!selectedAmount || selectedAmount < minimumDeposit}
            alignSelf="center"
            marginRight="0"
            size="lg"
            rightIcon={
              <ChevronIcon width={8} height={8} transform="rotate(-95deg)" />
            }
            isDisabled={
              !selectedAmount ||
              isAbove(selectedAmount, balance?.available) ||
              selectedAmount <= minimumDeposit
            }
            data-testid={'cashin-next-button'}
          >
            {t('common:next')}
          </Button>
        )}
      </BorderedBox>
    </>
  );

  const steps = [
    {
      component: SelectPaymentMethod,
      disableNextButton: selectedMethod.length === 0,
      displayNextButton: false
    },
    {
      component: SelectAmount,
      disableNextButton: !selectedAmount || selectedAmount < minimumDeposit,
      displayNextButton: !isSmMediaQuery
    },
    { component: <Aside /> }
  ];

  return (
    <Stepper
      data-testid="WalletCashInForm"
      steps={steps}
      flexOrderPrevious={isSmMediaQuery ? 0 : 2}
      flexOrderNext={isSmMediaQuery ? 2 : 3}
      handleNextStep={currentStep}
      callback={(newIndex: number) => {
        // Event need to be triggered only on Next button click
        if (newIndex === 2) {
          GTM.ecommerceEvent(
            'add_to_cart',
            selectedAmount * CurrencyBase.EURO,
            selectedPaymentMethod,
            userData,
            balance,
            !userData?.alreadyDeposited
          );
        }
        setHandleNextStep(newIndex);
      }}
    />
  );
};

export default WalletCashInForm;
