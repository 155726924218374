import { useCallback, useState } from 'react';
import { breakpoints } from '../theme/base';
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect';

const useMediaQuery = (condition: string) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: any) => {
    if (e?.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    Object.keys(breakpoints).forEach((breakpointKey) => {
      if (condition.includes(breakpointKey)) {
        condition = condition.replace(
          breakpointKey,
          breakpoints[breakpointKey]
        );
      }
    });
    const media = window?.matchMedia?.(condition);
    media?.addEventListener?.('change', updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media?.matches) {
      setTargetReached(true);
    }

    return () => media?.removeEventListener('change', updateTarget);
  }, []);

  return targetReached;
};

export default useMediaQuery;
